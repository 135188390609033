<template>
  <b-card :title="`${$t('General.Add')} ${$t('Document')}`">
    <validation-observer ref="simpleRules">
      <b-overlay
        :show="showOverLay"
        rounded="sm"
        :opacity="0.5"
      >
        <b-form ref="formName">
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('General.ArabicName')"
                label-for="arabicName"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('General.ArabicName')"
                  rules="required"
                >
                  <b-form-input
                    v-model.lazy="name_ar"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('General.ArabicName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('General.EnglishName')"
                label-for="englishName"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('General.EnglishName')"
                  rules="required"
                >
                  <b-form-input
                    v-model.lazy="name_en"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('General.EnglishName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <!-- <validation-provider
                #default="{ errors }"
                :name="$t('General.Parent')"
                rules="required"
              > -->
              <!-- :state="errors.length > 0 ? false:null" -->
              <b-form-group
                :label="$t('General.Parent')"
                label-for="parent"
              >
                <!-- :state="parent === null ? false : true" -->
                <v-select
                  v-model="parent"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                  :options="parent_option"
                  :placeholder="$t('General.Parent')"
                />
                <!-- <b-form-invalid-feedback
                  :state="errors.length > 0 ? false:null"
                >
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-invalid-feedback> -->
              </b-form-group>
              <!-- </validation-provider> -->
            </b-col>
            <b-col md="3">
              <b-form-group
                :label="$t('General.Static Code')"
                label-for="staticCode"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('General.Static Code')"
                  rules="required"
                >
                  <b-form-input
                    v-model="static_code"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('General.Static Code')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                :label="$t('General.Digit')"
                label-for="digit"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('General.Digit')"
                  rules="required|regex:^([0-9]+)$"
                >
                  <b-form-input
                    v-model.number="digit"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('General.Digit')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                :label="$t('General.is Warehouse')"
                label-for="isWarehouse"
              >
                <b-form-checkbox
                  v-model="is_warehouse"
                  class="custom-control-primary"
                >
                  {{ $t('General.is Warehouse') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                :label="$t('General.is Accounting')"
                label-for="isAccounting"
              >
                <b-form-checkbox
                  v-model="is_accounting"
                  class="custom-control-primary"
                >
                  {{ $t('General.is Accounting') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                :label="$t('General.is Payment')"
                label-for="isPayment"
              >
                <b-form-checkbox
                  v-model="is_payment"
                  class="custom-control-primary"
                >
                  {{ $t('General.is Payment') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                :label="$t('General.Active')"
                label-for="active"
              >
                <b-form-checkbox
                  v-model="active"
                  class="custom-control-primary"
                >
                  {{ $t('General.Active') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('General.Suspended')"
                label-for="suspended"
              >
                <b-form-checkbox
                  v-model="suspended"
                  class="custom-control-primary"
                >
                  {{ $t('General.Suspended') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <!-- alert -->
              <b-alert
                v-height-fade.appear
                variant="danger"
                :show="showError"
                class="text-left p-1"
              >
                <ul class="list-style-icons mb-0">
                  <li><feather-icon icon="ArrowRightIcon" />{{ apiErrors }}</li>
                </ul>
              </b-alert>
            </b-col>
            <b-col cols="3" />
            <b-col cols="3" />
            <b-col cols="3" />
            <b-col cols="6 text-center p-1">
              <b-button
                variant="primary"
                type="submit"
                block
                @click.prevent="validationForm"
              >
                {{ $t('General.Save') }}
              </b-button>
            </b-col>
            <b-col cols="3" />
          </b-row>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BOverlay, BAlert, BFormCheckbox,
} from 'bootstrap-vue'
import { required, integer } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightFade } from '@core/directives/animations'
import vSelect from 'vue-select'
import document from '@/service/administration/document'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BAlert,
    BFormCheckbox,
    vSelect,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      name_ar: '',
      name_en: '',
      required,
      integer,
      parent: null,
      static_code: '',
      digit: '',
      active: true,
      is_warehouse: false,
      is_accounting: false,
      is_payment: false,
      suspended: false,
      parent_option: [],
      showError: false,
      showOverLay: false,
      apiErrors: [],
      locale: this.$i18n.locale,
    }
  },
  async created() {
    this.getApiSelect()
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.postApi()
        }
        this.switchLoc()
      })
    },
    async postApi() {
      this.showOverLay = true
      const user = JSON.parse(localStorage.getItem('userData'))
      await document.postAxios({
        name_ar: this.name_ar,
        name_en: this.name_en,
        parent: this.parent == null ? 0 : this.parent.id,
        static_code: this.static_code,
        digit: this.digit,
        active: this.active ? 1 : 0,
        is_warehouse: this.is_warehouse ? 1 : 0,
        is_accounting: this.is_accounting ? 1 : 0,
        is_payment: this.is_payment ? 1 : 0,
        suspended: this.suspended ? 1 : 0,
        user_id: user.id,
      }).then(() => {
        this.$refs.formName.reset()
        this.showError = false
        this.showToast(this.$t('General.Successfully'), 'CheckCircleIcon', this.$t('General.SavedSuccessfully'), 'success')
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
        this.showToast(this.$t('General.Error'), 'XCircleIcon', this.$t('General.SavedError'), 'danger')
      }).finally(() => {
        this.clearForm()
        this.showOverLay = false
      })
    },
    clearForm() {
      this.name_ar = ''
      this.name_en = ''
      this.parent = ''
      this.static_code = ''
      this.digit = ''
      this.active = true
      this.is_warehouse = true
      this.is_accounting = true
      this.is_payment = true
      this.suspended = true
      this.$refs.simpleRules.reset()
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    switchLoc() {
      this.locale = this.$i18n.locale
      localize(this.locale)
    },
    async getApiSelect() {
      this.showOverLay = true
      await document.getAxios().then(response => {
        this.parent_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      }).finally(() => { this.showOverLay = false })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
